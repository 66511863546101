import { Component, lazy } from 'react';

export const publicRoutes = [
  {
    Component: lazy(() => import('../components/SignIn/sign-in.component')),
    path: '/',
  },
  {
    Component: lazy(() => import('../components/forgetPassword')),
    path: '/forgot-password',
  },
  {
    Component: lazy(() => import('../components/confirmPassword')),
    path: '/confirm-password/:uname',
  },

  // {
  //   Component: lazy(() => import('../components/SignUp/sign-up.component')),
  //   path: '/sign-up',
  // },
  // {
  //   Component: lazy(() => import('../components/SignUp/sign-up1.component')),
  //   path: '/select-product',
  // },
  {
    path: '*',
    Component: lazy(() => import('screens/404.screen')),
  },
  {
    path: '/otp-page',
    Component: lazy(() => import('screens/OtpVerification')),
  },
  {
    path: 'reset-password',
    Component: lazy(() => import('components/SignIn/ResetPassword')),
  },
];

export const extraRoutesClient = [
  // {
  //   path: '/edit-profile/',
  //   Component: lazy(() => import('screens/client/EditProfile.screen')),
  // },

  {
    path: '*',
    Component: lazy(() => import('screens/404.screen')),
  },
];

export const outerRoutesEmployee = [
  // TEMPLATEs ROUTES #001199
  {
    path: '/audit-access/',
    Component: lazy(() => import('screens/auditAccess')),
  },
  {
    path: '/docket/:id',
    Component: lazy(() => import('components/Docket/Docket')),
  },
  {
    path: '/print-barcodes/:id/:gridValue/:items/:txn_type',
    Component: lazy(() => import('screens/employee/ASNStuff/PrintBarcodes')),
  },
  {
    path: '/invoice-template/:id',
    Component: lazy(() =>
      import('components/Templates/InvoiceTemplates/Invoice.template')
    ),
  },

  {
    path: '/purchase-order-template/:id',
    Component: lazy(() =>
      import('components/Templates/PurchaseOrderTemplate/PurchaseOrderTemplate')
    ),
  },
  {
    path: '/asn-template/:id/:type',
    Component: lazy(() =>
      import('components/Templates/ASNTemplate/ASNTemplate')
    ),
  },
  {
    path: '/grn-template/:id/:type',
    Component: lazy(() =>
      import('components/Templates/GRNTemplate/GRN.template')
    ),
  },
  {
    path: '/dc-allotment-template/:id',
    Component: lazy(() =>
      import('components/Templates/DCAllotmentTemplate/DCAllotment.template')
    ),
  },

  {
    path: '/dc-allotment-slip/:id',
    Component: lazy(() => import('components/Slip/DCPackageSlip')),
  },
  {
    path: '/dc-return-template/:id/:type',
    Component: lazy(() =>
      import('components/Templates/DCReturnTemplate/DCReturn.template')
    ),
  },
  {
    path: '/dc-return-slip/:id/:type',
    Component: lazy(() => import('components/Slip/DCReturnSlip')),
  },
  {
    path: '/dc-relocation-template/:id',
    Component: lazy(() =>
      import('components/Templates/DCRelocationTemplate/DCRelocationTemplate')
    ),
  },

  {
    path: '/return-docket/:id',
    Component: lazy(() => import('components/ReturnDocket/ReturnDocket')),
  },

  {
    path: '/outward-docket/:id',
    Component: lazy(() => import('components/OutwardsDocket/outward-docket')),
  },
  {
    path: '/relocation-docket/:id',
    Component: lazy(() =>
      import('components/RelocationDocket/RelocationDocket')
    ),
  },
  {
    path: '/grn-barcode/',
    Component: lazy(() => import('components/GRN/barcodeContainer')),
  },
  {
    name: 'Purchase Order Print',
    path: '/purchase-order/:id',
    Component: lazy(() =>
      import('screens/employee/GRNStuff/PurchaseOrderPrint')
    ),
    props: { isEmployee: true },
  },
];

export const outerRoutesClient = [
  {
    path: '/return-docket/',
    Component: lazy(() => import('components/ReturnDocket/ReturnDocket')),
    props: { isClient: true },
  },
  {
    path: '/outward-docket/:id',
    Component: lazy(() => import('components/OutwardsDocket/outward-docket')),
  },
];

export const extraRoutesEmployee = [
  {
    path: '/empty-stock/add',
    Component: lazy(() => import('forms/emptyStockForm')),
  },
  {
    path: '/empty-stock/edit/:id',
    Component: lazy(() => import('forms/emptyStockForm')),
  },
  {
    path: '/empty-stock/clone/:cloneId',
    Component: lazy(() => import('forms/emptyStockForm')),
  },
  {
    path: '/org/perefrences/',
    Component: lazy(() => import('screens/employee/Preference')),
  },
  {
    path: '/edit-profile/',
    Component: lazy(() => import('screens/employee/EditProfile.screen')),
  },
  {
    path: '/edit-vendor/:id',
    Component: lazy(() => import('forms/vendor.form')),
  },

  {
    path: '/delivery-challan-relocation/add',
    Component: lazy(() => import('forms/DCRelocationForm')),
  },
  {
    path: '/delivery-challan-vendor/add',
    Component: lazy(() => import('forms/challanVendorForm')),
  },
  {
    path: '/delivery-challan-relocation/edit/:id',
    Component: lazy(() => import('forms/DCRelocationForm')),
  },
  {
    path: '/delivery-challan-vendor/edit/:id',
    Component: lazy(() => import('forms/challanVendorForm')),
  },

  {
    path: '/master/add-flow/',
    Component: lazy(() => import('screens/employee/flow.form')),
  },
  {
    path: '/master/add-flow/:isUsed/:id',
    Component: lazy(() => import('screens/employee/flow.form')),
  },
  {
    path: '/delivery-challan-outward/add',
    Component: lazy(() => import('forms/challanOutward')),
  },
  {
    path: '/delivery-challan-outward/edit/:id',
    Component: lazy(() => import('forms/challanOutward')),
  },
  {
    path: '/delivery-challan-relocation/add',
    Component: lazy(() => import('forms/challanRelocation')),
  },
  {
    path: '/delivery-challan-relocation/edit/:id',
    Component: lazy(() => import('forms/challanRelocation')),
  },

  {
    path: '/delivery-challan-allotment/add',
    Component: lazy(() => import('forms/challanAllotment')),
  },

  {
    path: '/delivery-challan-allotment/add/:mrId',
    Component: lazy(() => import('forms/challanAllotment')),
  },
  {
    path: '/delivery-challan-allotment/clone/:cloneId',
    Component: lazy(() => import('forms/challanAllotment')),
  },
  {
    path: '/pfep/leads/add',
    Component: lazy(() => import('forms/leadsV1.form')),
  },
  {
    path: '/pfep/leads/edit/:id',
    Component: lazy(() => import('forms/leadsV1.form')),
  },
  {
    path: '/pfep/leads/scs/:lead',
    Component: lazy(() => import('forms/PFEP/SCSMain2.form')),
  },

  {
    path: '/pfep/tp/template/:id',
    Component: lazy(() =>
      import('../components/Templates/TPTemplate/TPTemplate')
    ),
  },
  {
    path: '/pfep/tp/edit/:id',
    Component: lazy(() => import('forms/CreateTP/mainCreateTP.form')),
  },
  {
    path: '/cp/edit/:id',
    Component: lazy(() => import('forms/CreateCP/mainCreateCP2.form')),
  },
  {
    path: '/pfep/scs/edit/:id',
    Component: lazy(() => import('forms/PFEP/SCSMain2.form')),
  },
  {
    path: '/expense/edit/:id',
    Component: lazy(() => import('forms/expense.form')),
  },

  {
    path: '/pfep/scs/tp-summary/:id',
    Component: lazy(() => import('screens/employee/TPSummary')),
  },
  {
    path: '/expense/add',
    Component: lazy(() => import('forms/expense.form')),
  },
  {
    path: '/pfep/cp/',
    Component: lazy(() => import('forms/CreateCP/mainCreateCP2.form')),
  },
  {
    path: '/pfep/scs/tp/',
    Component: lazy(() => import('forms/CreateTP/mainCreateTP.form')),
  },
  {
    path: '/delivery-challan-return/add',
    Component: lazy(() => import('forms/challanReturn')),
  },
  {
    path: '/create-grn/',
    Component: lazy(() => import('screens/employee/grnasn.screen')),
  },
  {
    path: '/asn/create-grn/:asnId',
    Component: lazy(() => import('screens/employee/grnasn.screen')),
  },
  {
    path: '/asn/edit-grn/:grnId',
    Component: lazy(() => import('screens/employee/grnasn.screen')),
  },

  {
    path: '/edit-grn/:id',
    Component: lazy(() => import('screens/employee/grnasn.screen')),
  },
  // {
  //   path: '/manage-profile/',
  //   Component: lazy(() => import('forms/manageUser.form')),
  // },

  {
    path: '*',
    Component: lazy(() => import('screens/404.screen')),
  },

  {
    path: '/grn-barcode/',
    Component: lazy(() => import('components/GRN/barcodeContainer')),
  },

  {
    path: '/purchase-orders/:id',
    Component: lazy(() => import('forms/purchaseOrder.form')),
  },
  {
    path: '/purchase-orders/clone/:cloneId',
    Component: lazy(() => import('forms/purchaseOrder.form')),
  },

  {
    path: '/delivery-challan-allotment/edit/:id/:type',
    Component: lazy(() => import('forms/challanAllotment')),
  },
  {
    path: '/invoice/edit/:id/',
    Component: lazy(() => import('forms/allotmentInvoice.form')),
  },
  {
    path: '/create-allotment-invoice/:altId/:altNumber',
    Component: lazy(() => import('forms/allotmentInvoice.form')),
  },
  {
    path: '/master/delivery-challan-vendor/add',
    Component: lazy(() => import('forms/vendor.form')),
  },
  {
    path: '/master/delivery-challan-vendor/edit/:id',
    Component: lazy(() => import('forms/vendor.form')),
  },
  {
    path: '/delivery-challan-return/edit/:id',
    Component: lazy(() => import('forms/challanReturn')),
  },
  {
    path: '/delivery-challan-return/add/:emptyId',
    Component: lazy(() => import('forms/challanReturn')),
  },
  {
    path: '/customer-form/',
    Component: lazy(() => import('screens/employee/CustomerFormRoute.screen')),
  },
  {
    path: '/master/vendor-form/',
    Component: lazy(() => import('forms/vendor.form')),
  },
  // {
  //   path: '/invoice-template/',
  //   Component: lazy(() => import('forms/Customer.form')),
  // },

  {
    path: '/master/items/add',
    Component: lazy(() => import('screens/employee/createItemForm')),
  },
  {
    path: '/master/items/edit/:id',
    Component: lazy(() => import('screens/employee/createItemForm')),
  },
  {
    path: '/master/items/add-group',
    Component: lazy(() => import('screens/employee/createItemGroupForm')),
  },
  {
    path: '/master/items/edit-group/:id',
    Component: lazy(() => import('screens/employee/createItemGroupForm')),
  },

  {
    path: '/master/items/add-group',
    Component: lazy(() => import('screens/employee/createItemGroupForm')),
  },

  {
    path: '/master/warehouses/add',
    Component: lazy(() => import('screens/employee/addWarehouseForm')),
  },

  {
    path: '/packaging/add',
    Component: lazy(() => import('forms/Packaging.from')),
  },
  {
    path: '/packaging/:id',
    Component: lazy(() => import('forms/Packaging.from')),
  },
  {
    path: '/purchase-orders/add',
    Component: lazy(() => import('forms/purchaseOrder.form')),
  },
  {
    // path: '/asn/add/:poId/:type',
    path: '/asn/add/:poId/:type',
    Component: lazy(() => import('screens/employee/CreateASN')),
  },
  {
    path: '/asn/edit/:id',
    Component: lazy(() => import('screens/employee/CreateASN')),
  },

  {
    path: '/customer-form/',
    Component: lazy(() => import('screens/employee/CustomerFormRoute.screen')),
  },

  {
    path: '/master/items/add',
    Component: lazy(() => import('screens/employee/createItemForm')),
  },

  {
    path: '/master/items/add-group',
    Component: lazy(() => import('screens/employee/createItemGroupForm')),
  },

  {
    path: '/master/items/add-group',
    Component: lazy(() => import('screens/employee/createItemGroupForm')),
  },

  {
    path: '/report/:title/',
    Component: lazy(() => import('components/Reports/createReport')),
  },
  {
    path: '/master/user/add',
    Component: lazy(() => import('forms/SuperUser/addUser.form')),
  },
  {
    path: '/master/user/add/:id',
    Component: lazy(() => import('forms/SuperUser/addUser.form')),
  },

  {
    path: '/add-adjustment-inventory/',
    Component: lazy(() => import('forms/adjustmentInventory.form')),
  },
  {
    path: '/add-end-of-life/',
    Component: lazy(() => import('forms/endOfLife.form')),
  },
  {
    path: '/add-dashboard-correction/',
    Component: lazy(() => import('forms/dashboardCorrection.form')),
  },
  {
    path: '/dashboard-correction/:id',
    Component: lazy(() => import('forms/dashboardCorrection.form')),
  },
  {
    path: '/allotment-cal/:txn/:txnId',
    Component: lazy(() =>
      import('screens/employee/DeliveryChallanAllotmentTable.screen')
    ),
  },
  {
    path: '/return-cal/:txn/:txnId',
    Component: lazy(() =>
      import('screens/employee/DeliveryChallanReturnTable.screen')
    ),
  },
  {
    path: '/outward-cal/:txn/:txnId',
    Component: lazy(() =>
      import('screens/employee/DeliveryChallanOutwardTable.screen')
    ),
  },
  {
    path: '/relocation-cal/:txn',
    Component: lazy(() =>
      import('screens/employee/DeliveryChallanRelocationTable.screen')
    ),
  },
  {
    path: '/pfep/:txn/:txnId',
    Component: lazy(() => import('screens/employee/SCS.screen')),
  },
  {
    path: '/tp-sales/:txn/:txnId',
    Component: lazy(() => import('screens/employee/TP.screen')),
  },
  // Component: lazy(() => import('screens/employee/createCP.screen')),
  // Component: lazy(() => import('screens/employee/TP.screen')),
  //   Component: lazy(() => import('screens/employee/SCS.screen')),
  {
    path: '/tp-sales/:txn/:txnId',
    Component: lazy(() => import('screens/employee/TP.screen')),
  },
  {
    path: '/cp-sales/:txn/:txnId',
    Component: lazy(() => import('screens/employee/createCP.screen')),
  },

  {
    path: '/add-tickets',
    Component: lazy(() => import('forms/ticket.form')),
  },
  {
    path: '/create-audit/add',
    Component: lazy(() => import('forms/createAudit.form')),
  },
  {
    path: '/edit-audit/:id',
    Component: lazy(() => import('forms/createAudit.form')),
  },
];

export const RTIRoutes = [
  {
    name: 'Dashboard',
    icon: ['fa-solid', 'fa-chart-pie'],
    path: '/dashboard',
    Component: lazy(() => import('screens/employee/Employee.Dashboard')),
  },
  // {
  //   name: 'Dashboard V1',
  //   icon: ['fa-solid', 'fa-chart-pie'],
  //   path: '/dashboardv1',
  //   Component: lazy(() => import('components/Tableau')),
  // },
  {
    name: 'Masters',
    // key: 'masters',
    icon: ['fas', 'layer-group'],
    path: '/masters/',
    isSubMenu: true,
    subMenu: [
      {
        name: 'Warehouse',
        // key: 'masters_warehouses',
        path: '/master/warehouses/',
        Component: lazy(() =>
          import('screens/employee/Warehouse.employee.screen')
        ),
      },

      {
        name: 'Item',
        // key: 'masters_products',
        path: '/master/items/',
        Component: lazy(() =>
          import('screens/employee/Product.employee.screen')
        ),
      },
      {
        name: 'Item Group',
        // key: 'masters_kits',
        path: '/master/item-group/',
        Component: lazy(() => import('screens/employee/Kit.employee.screen')),
      },

      {
        // key: 'customers',
        name: 'Customers',
        path: '/customer/',
        Component: lazy(() => import('screens/employee/Customer.screen')),
      },
      {
        name: 'Flows',
        // key: 'masters_flow',
        path: '/master/flows/',
        Component: lazy(() => import('screens/employee/Flow.screen')),
      },
      {
        name: 'Vendors',
        // key: 'masters_vendors',
        path: '/master/vendors/',
        Component: lazy(() => import('screens/employee/Vendors.screen')),
      },
      // {
      //   name: 'Users',
      //   // key: 'employee',
      //   path: '/manage/users/',
      //   Component: lazy(() => import('screens/superUser/users.screen')),
      // },
    ],
  },
  {
    name: 'Inbound',
    // key: 'inbound',
    icon: ['fas', 'fa-sign-in-alt'],
    path: '/inbound/',
    isSubMenu: true,
    subMenu: [
      {
        name: 'Purchase Order',
        // key: 'purchase_order',
        path: '/purchase-orders/',
        icon: ['fas', 'fa-sign-out-alt'],
        Component: lazy(() => import('screens/employee/purchaseOrders.screen')),
      },

      {
        name: 'ASN',
        // key: 'view_asn',
        path: '/asn/',
        Component: lazy(() => import('screens/employee/ASN.screen')),
      },
      {
        name: 'GRN',
        // key: 'grn',
        path: '/grn/',
        Component: lazy(() => import('screens/employee/GRNTable.screen')),
      },

      {
        name: 'Return',
        // key: 'return',
        path: '/return/',
        Component: lazy(() =>
          import('screens/employee/DeliveryChallanReturnTable.screen')
        ),
        props: { isEmployee: true },
      },
    ],
  },
  {
    name: 'Outbound',
    // key: 'outbound',
    icon: ['fas', 'fa-sign-out-alt'],
    path: '/outbound/',
    isSubMenu: true,
    subMenu: [
      {
        name: 'DC-Vendor',
        path: '/dc-vendor/',
        Component: lazy(() =>
          import('screens/employee/DeliveryChallanVendorTable.screen')
        ),
      },
      {
        name: 'Material Request',
        // key: 'material_request',
        path: '/material-request/',
        Component: lazy(() =>
          import('screens/employee/MaterialRequest.screen')
        ),
      },
      {
        name: 'Allotment',
        // key: 'allotment',
        path: '/allotment/',
        Component: lazy(() =>
          import('screens/employee/DeliveryChallanAllotmentTable.screen')
        ),
      },
      {
        name: 'Outward',
        // key: 'outward',
        path: '/outward/',
        Component: lazy(() =>
          import('screens/employee/DeliveryChallanOutwardTable.screen')
        ),
      },
      {
        name: 'Empty Stock',
        // key: 'empty_stock',
        path: '/empty-stock',
        Component: lazy(() => import('screens/employee/EmptyStock.screen')),
      },
      {
        name: 'Relocation',
        // key: 'relocation',
        path: '/relocation/',
        Component: lazy(() =>
          import('screens/employee/DeliveryChallanRelocationTable.screen')
        ),
        props: { isEmployee: true },
      },
    ],
  },
  {
    name: 'Sales',
    icon: ['fas', 'user-friends'],
    path: '/reports/',
    isSubMenu: true,
    subMenu: [
      // {
      //   name: 'Leads',
      //   path: '/pfep/leads/',
      //   Component: lazy(() => import('screens/employee/Leads.screen')),
      // },
      {
        name: 'Leads',
        path: '/pfep/leads/',
        Component: lazy(() => import('screens/employee/LeadScreen')),
      },
      {
        name: 'SCS',
        path: '/pfep/scs/',
        Component: lazy(() => import('screens/employee/SCS.screen')),
      },
      {
        name: 'TP',
        path: '/pfep/tp/',
        Component: lazy(() => import('screens/employee/TP.screen')),
      },

      {
        name: 'CP',
        path: '/cp/',
        Component: lazy(() => import('screens/employee/createCP.screen')),
        icon: ['fas', 'user-friends'],
      },
    ],
  },

  {
    name: 'Tickets',
    icon: ['fas', 'fa-ticket-alt'],
    path: '/tickets-kanban/',
    Component: lazy(() => import('screens/TicketsKanban')),
  },

  {
    name: 'Expense',
    icon: ['fas', 'money-check-alt'],
    path: '/expense/',
    Component: lazy(() => import('screens/employee/Expense.screen')),
    props: { isEmployee: true },
  },
  {
    name: 'Invoice',
    icon: ['fas', 'fa-receipt'],
    path: '/invoices/',
    Component: lazy(() => import('screens/employee/Invoice.screen')),
    props: { isEmployee: true },
  },
  {
    name: 'Audit',
    icon: ['fas', 'fa-search'],
    path: '/new-audit/',
    // icon: ['fa-solid fa-question'],
    Component: lazy(() => import('screens/employee/createAudit.screen')),
  },
  // {
  //   name: 'Lifecycle Management',
  //   icon: ['fas', 'layer-group'],
  //   path: '/lifecycle-management/',
  //   isSubMenu: true,
  //   subMenu: [
  //     {
  //       name: 'Audit',
  //       path: '/new-audit/',
  //       icon: ['fa-solid fa-question'],
  //       Component: lazy(() => import('screens/employee/createAudit.screen')),
  //     },
  //     // {
  //     //   name: 'Audit',
  //     //   path: '/audit/',
  //     //   // key: 'audit',
  //     //   Component: lazy(() =>
  //     //     import('screens/employee/yantraInventory.screen')
  //     //   ),
  //     // },
  //   ],
  // },

  // {
  //   name: 'Adjustments Inventory',
  //   icon: ['far', 'fa-chart-bar'],
  //   path: '/djustments',
  //   isSubMenu: true,
  //   subMenu: [
  //     {
  //       name: 'Adjustments Inventory',
  //       // key: 'reports_inventory',
  //       path: '/djustments-inventory',
  //       Component: lazy(() =>
  //         import('screens/employee/adjustmentInventory.screen')
  //       ),
  //     },
  //     // {
  //     //   name: 'Dashboard Correction',
  //     //   // key: 'reports_inventory',
  //     //   path: '/dashboard-correction',
  //     //   Component: lazy(() =>
  //     //     import('screens/employee/DashboardCorrection.screen')
  //     //   ),
  //     // },
  //     {
  //       name: 'End Of Life',
  //       // key: 'reports_inventory',
  //       path: '/end-of-life',
  //       Component: lazy(() => import('screens/employee/endOfLife.screen')),
  //     },
  //   ],
  // },
  {
    name: 'Report',
    // key: 'reports_inventory',
    path: '/all-reports/',
    icon: ['far', 'fa-chart-bar'],
    Component: lazy(() => import('screens/Reports.screen')),
  },
  {
    name: 'AMCS',
    icon: ['fas', 'user-friends'],
    path: '/amcs/',
    isSubMenu: true,
    subMenu: [
      {
        name: 'Inventory',
        // key: 'reports_inventory',
        path: '/main-inventory',
        Component: lazy(() =>
          import('screens/employee/yantraInventory.screen')
        ),
        icon: ['fas', 'fa-boxes'],
      },
      {
        name: 'Adjustments Inventory',
        // key: 'reports_inventory',
        path: '/djustments-inventory',
        Component: lazy(() =>
          import('screens/employee/adjustmentInventory.screen')
        ),
      },
      {
        name: 'Dashboard Correction',
        // key: 'reports_inventory',
        icon: ['fas', 'fa-pencil-alt'],
        path: '/dashboard-correction',
        Component: lazy(() =>
          import('screens/employee/DashboardCorrection.screen')
        ),
      },
    ],
  },

  // {
  //   name: 'NEW Report',
  //   // key: 'reports_inventory',
  //   path: '/new-reports/',
  //   Component: lazy(() => import('screens/NewReport.screeen')),
  // },

  // {
  //   name: 'Reports',
  //   icon: ['far', 'fa-chart-bar'],
  //   path: '/demands/',
  //   // key: 'reports',
  //   isSubMenu: true,
  //   subMenu: [
  //     // {
  //     //   name: 'NEW Report',
  //     //   // key: 'reports_inventory',
  //     //   path: '/new-reports/',
  //     //   Component: lazy(() => import('screens/NewReport.screeen')),
  //     // },
  //     {
  //       name: 'Report',
  //       // key: 'reports_inventory',
  //       path: '/all-reports/',
  //       Component: lazy(() => import('screens/Reports.screen')),
  //     },
  //     {
  //       name: 'Schedule Report',
  //       // key: 'reports_inventory',
  //       path: '/schedule-reports/',
  //       Component: lazy(() => import('forms/scheduleReports.form')),
  //     },
  //     {
  //       name: 'Inventory',
  //       // key: 'reports_inventory',
  //       path: '/main-inventory',
  //       Component: lazy(() =>
  //         import('screens/employee/yantraInventory.screen')
  //       ),
  //     },

  //     // {
  //     //   name: 'Adjustments Inventory',
  //     //   // key: 'reports_inventory',
  //     //   path: '/djustments-inventory',
  //     //   Component: lazy(() =>
  //     //     import('screens/employee/adjustmentInventory.screen')
  //     //   ),
  //     // },
  //   ],
  // },
];

export const RTICustomerRoutes = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    Component: lazy(() => import('screens/employee/Employee.Dashboard')),
  },
  {
    name: 'Material Request',
    path: '/material-request/',
    Component: lazy(() => import('screens/RTICustomer/MaterialRequest.screen')),
  },
  {
    name: 'Allotment',
    path: '/allotment/',
    Component: lazy(() =>
      import('screens/employee/DeliveryChallanAllotmentTable.screen')
    ),
  },
  {
    name: 'Outward',
    path: '/outward/',
    Component: lazy(() =>
      import('screens/employee/DeliveryChallanOutwardTable.screen')
    ),
  },
  {
    name: 'Return',
    path: '/return/',
    Component: lazy(() =>
      import('screens/employee/DeliveryChallanReturnTable.screen')
    ),
  },

  {
    name: 'Reports',
    path: '/all-reports/',
    Component: lazy(() => import('screens/Reports.screen')),
  },
  {
    name: 'Inventory',
    path: '/main-inventory',
    Component: lazy(() => import('screens/employee/customerInventory.screen')),
  },
];

export const RTIVendorRoutes = [
  {
    name: 'Purchase Order',
    path: '/purchase-orders/',
    Component: lazy(() => import('screens/employee/purchaseOrders.screen')),
  },
  {
    name: 'ASN',
    path: '/asn/',
    Component: lazy(() => import('screens/employee/ASN.screen')),
  },
];

export const superUserRoutes = [];

export const extraRoutesSuperUser = [];

export const outerRoutesSuperUser = [];
